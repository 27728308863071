
import { defineComponent, ref, onMounted, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ColmunsProps, ColmunsChildrenProps, FileInfo, FileResponse } from '@/API/types';
import expandIcon from '@/hooks/useExpandIcon';
import { MaintainChildren, MaintainCellProps, MaintainColProps } from './types';
import {getBuList} from '@/API/approvalChain'
import {
  getTypeModelYearList,
  getFileList,
  getCustomerType,
  getBrandList,
  getCalculatorMaintain,
  getSelectVehicle,
  getAllBu,
  getBrandByBu,
  uploadCalculator,
  validateCalculator
} from '@/API/finance';
import deepClone from '@/utils/deepClone';
import MaintainTable from './components/MaintainTable.vue';
import { message, Modal } from 'ant-design-vue';
import blobDownload from '@/utils/blobDownload';
import useTableHeight from '@/hooks/useTableHeight';
import { baseURL } from '@/API';
import moment from 'moment';
import { TreeSelect } from 'ant-design-vue';
import SupplementaryCalculator from './components/SupplementaryCalculator.vue';
interface FileProps {
  excelName: string;
  sourceFilePath: string;
}
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  disabled?: boolean;
  children?: TreeDataItem[];
}
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const qmOptions: TreeDataItem[] = [
  {
    title: 'Q1',
    value: 'Q1',
    key: 'Q1',
    children: [
      {
        title: '01',
        value: '01',
        key: '01'
      },
      {
        title: '02',
        value: '02',
        key: '02'
      },
      {
        title: '03',
        value: '03',
        key: '03'
      }
    ]
  },
  {
    title: 'Q2',
    value: 'Q2',
    key: 'Q2',
    children: [
      {
        title: '04',
        value: '04',
        key: '04'
      },
      {
        title: '05',
        value: '05',
        key: '05'
      },
      {
        title: '06',
        value: '06',
        key: '06'
      }
    ]
  },
  {
    title: 'Q3',
    value: 'Q3',
    key: 'Q3',
    children: [
      {
        title: '07',
        value: '07',
        key: '07'
      },
      {
        title: '08',
        value: '08',
        key: '08'
      },
      {
        title: '09',
        value: '09',
        key: '09'
      }
    ]
  },
  {
    title: 'Q4',
    value: 'Q4',
    key: 'Q4',
    children: [
      {
        title: '10',
        value: '10',
        key: '10'
      },
      {
        title: '11',
        value: '11',
        key: '11'
      },
      {
        title: '12',
        value: '12',
        key: '12'
      }
    ]
  }
];
interface BrandResponse {
  id: number;
  nameCn: string;
  nameEn: string;
  parentId: string;
  deleted: number;
  budgetBu: string;
}
export default defineComponent({
  name: 'Calculator Maintain',
  components: {
    MaintainTable,
    SupplementaryCalculator
  },

  setup() {
    // 获取表格的高度
    const { tableHeight } = useTableHeight(true, '.pagination-box');
    // 标识当前的类型 fa 、buy down
    const offerType = ref('fa');

    // 年份
    const year = ref<string>(moment().add('month', 1).format('YYYY'));
    const openYear = ref<boolean>(false);
    const handlerOpenChange = (status: any): void => {
      if (status) {
        openYear.value = true;
      }
    };
    const handlerPanelChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      year.value = time as string;
      openYear.value = false;
    };

    // 季度 月份
    const getDefaultQm = (): string => {
      return 'Q' + moment().add('month', 1).quarter();
    };
    const qm = ref<string[]>([getDefaultQm()]);

    // 选择品牌
    const valueBrand = ref<string>('');
    const valueBu = ref<string>('');
    const valueCustomer = ref('');
    const valueMark = ref('');
    // 用于获取选择日期的子组件
    const RefselectPeriod = ref();

    const store = useStore();
    const router = useRouter();

    // 导航的数据
    const activeKey = ref('normal');

    // 表格的数据
    const dataSource = ref<MaintainCellProps[]>([]);

    // table colums 的配置
    // 获取children中的数据，进行配置
    const deployChild = (arr: MaintainChildren[], type?: string): ColmunsChildrenProps[] => {
      const children: ColmunsChildrenProps[] = [];
      arr.forEach((item: MaintainChildren) => {
        if (type === 'fixedLeft') {
          if (item.display === 'Model') {
            children.push({
              title: item.display,
              dataIndex: item.id,
              key: item.id,
              align: 'center',
              width: 230,
              slots: { customRender: 'model' }
            });
          } else {
            children.push({
              title: item.display,
              dataIndex: item.id,
              key: item.id,
              width: 130,
              align: 'center'
            });
          }
        } else if (type === 'fixedRight') {
          children.push({
            title: item.display,
            dataIndex: item.id,
            key: item.id,
            width: 110,
            slots: { customRender: 'fixedRight' },
            align: 'center'
          });
        } else {
          children.push({
            title: item.display,
            dataIndex: item.id,
            key: item.id,
            slots: { customRender: 'active' },
            width: 160,
            align: 'center'
          });
        }
      });
      return children;
    };

    // 获取当前列的个数（如果列个数不足以撑开表格，就设置固定了）
    const findColLength = (data: MaintainColProps[]): number => {
      const colArr: string[] = [];
      const childrenArr: any[] = [];
      data.forEach((col) => {
        for (const key in col) {
          if (key === 'children') {
            childrenArr.push(col[key]);
          }
        }
      });
      childrenArr.flat().forEach((item) => {
        colArr.push(item.dataIndex);
      });
      return childrenArr.flat().length;
    };

    const columns = ref<ColmunsProps[]>([]);
    const configColumns = (data: MaintainColProps[]) => {
      // 初始化一次columns数据 防止再次获取数据的时候出现重复数据
      columns.value = [];
      const length = findColLength(data);
      data.forEach((item: MaintainColProps, index: number) => {
        //判断是否是第一个 最后一个，并是否有中间滚动的部分
        if (index === 0 && data.length > 3 && length > 9) {
          columns.value.push({
            title: item.display,
            fixed: 'left',
            children: deployChild(item.children, 'fixedLeft')
          });
        } else if (index === data.length - 1 && data.length > 3 && length > 9) {
          columns.value.push({
            title: ''
          });
          columns.value.push({
            title: item.display,
            fixed: 'right',
            children: deployChild(item.children, 'fixedRight')
          });
        } else if (index === 0) {
          columns.value.push({
            title: item.display,
            children: deployChild(item.children, 'fixedLeft')
          });
        } else if (index === data.length - 1) {
          columns.value.push({
            title: item.display,
            children: deployChild(item.children as MaintainChildren[], 'fixedRight')
          });
        } else {
          columns.value.push({
            title: item.display,
            children: deployChild(item.children as MaintainChildren[])
          });
        }
      });
      if (data.length === 2) {
        columns.value.splice(1, 0, {
          title: ''
        });
      }
    };

    // table的数据
    // 查找parent对象 通过id
    const findParent = (id: string, data: MaintainCellProps[]) => {
      const arr = [];
      for (const obj of data) {
        if (obj.parentId === id && obj.parentId !== obj.id) {
          arr.push(obj);
        }
      }
      return arr;
    };

    // 添加children字段
    const addChildren = (data: MaintainCellProps[]): MaintainCellProps[] => {
      let arr: MaintainCellProps[] = deepClone(data);
      arr.forEach((item: MaintainCellProps) => {
        if (item.parentId === item.id) {
          item['children'] = findParent(item.id, arr);
        }
      });
      arr = arr.filter((item: MaintainCellProps) => item.id === item.parentId);
      return arr;
    };

    // 分页
    const pageIndex = ref<number>(1);
    const pageSize = ref<number>(10);
    const totalElements = ref();

    // 初始化数据
    const brandsSelect = ref();
    const buSelect = ref();
    const buyBrandSelect = ref();
    const customerSelect = ref();
    const markSelect = reactive(['Order Protection', 'Pre Sale']);

    // 获取下拉选择内容
    const getSelectData = async () => {
      // CustomerType
      customerSelect.value = await getCustomerType();
      // 获取brand list FA
      brandsSelect.value = await getBrandList();
      valueBrand.value = brandsSelect.value[0];

      // 获取bu
      buSelect.value = await getBuList();
    };

    // 获取table数据
    const initCalculatorMaintain = () => {
      const params = {
        offerType: offerType.value,
        bu: valueBu.value,
        brand: valueBrand.value,
        customerType: valueCustomer.value,
        offer: valueMark.value,
        pageIndex: pageIndex.value - 1,
        pageSize: pageSize.value
      };
      getCalculatorMaintain({ params }).then((res: any) => {
        res.maintain.tableCol.sort((x: any, y: any) => {
          return x.id - y.id;
        });
        dataSource.value = addChildren(res.maintain.tableCell);
        configColumns(res.maintain.tableCol);
        // 总条数
        totalElements.value = +(res.maintain.totalElements as string);
      });
    };

    // 查询计算器数据
    const onSearch = () => {
      initCalculatorMaintain();
    };

    const initData = async () => {
      await getSelectData();
      initCalculatorMaintain();
    };

    // 跳转到更改状态 confirm re open的页面
    const jumpTo = (status: string) => {
      const params = {
        status,
        offer: valueMark.value,
        offerType: offerType.value,
        brand: valueBrand.value,
        bu: valueBu.value,
        customerType: valueCustomer.value
      };
      store.commit('finance/updateStateParmast', params);
      router.push({ name: 'Calculator Status' });
    };

    // 切换BU
    const handleSelectBu = () => {
      valueBrand.value = '';
      buyBrandSelect.value = [];
      // 根据bu 获取brand list
      const params = {
        bu: valueBu.value,
        target: 'Vehicle.brand'
      };
      getBrandByBu(params).then((res: any) => {
        buyBrandSelect.value = res;
      });
    };

    // 分页改变
    const changePage = (pagenumber: number, pagesize: number) => {
      pageSize.value = pagesize;
      pageIndex.value = pagenumber;
      initCalculatorMaintain();
    };

    const changSize = (pageindex: number, pagesize: number) => {
      pageSize.value = pagesize;
      pageIndex.value = 1;
      initCalculatorMaintain();
    };

    // 切换面板
    const cutTabs = () => {
      if (activeKey.value === 'normal') {
        offerType.value = 'fa';
        valueBrand.value = brandsSelect.value[0];
      } else {
        valueBu.value = '';
        valueBrand.value = '';
        valueCustomer.value = '';
        valueMark.value = '';
        offerType.value = 'buydown';
      }
      initCalculatorMaintain();
    };

    // 选择brand的时候
    const handleSelectBrand = () => {
      initCalculatorMaintain();
    };

    // Vehicle drawer 抽屉
    const value1201 = ref();

    const visibleVehicle = ref(false);
    const isProgPard = ref(false);

    // vehicle Export &offerType=buydown&bu=&brand=MB&customerType=&offer=
    const classSelect = ref([]);
    const modelSelect = ref<any[]>([]);
    const vehicleSelectData = reactive({
      typeClass: [],
      typeClassAndModel: {},
      typeClassAndNstGroup: {}
    });
    const onVehicExport = () => {
      visibleVehicle.value = true;
      isProgPard.value = false;
      value1201.value = valueBrand.value;
      const params = {
        offerType: offerType.value,
        bu: valueBu.value,
        brand: valueBrand.value,
        customerType: valueCustomer.value,
        offer: valueMark.value
      };
      getSelectVehicle(params).then((res: any) => {
        Object.assign(vehicleSelectData, res);
        classSelect.value = res.typeClass;
      });
    };
    const vehicleSelectList = reactive({
      typeClass: [],
      typeClassMapperModelYear: {},
      modeYearMapperModel: {},
      modeYearMapperGroup: {}
    });
    // program Export
    const getSelectList = (param = {}) => {
      const params = {
        offerType: offerType.value,
        bu: valueBu.value,
        brand: valueBrand.value,
        customerType: valueCustomer.value,
        offer: valueMark.value,
        ...param
      };
      return getTypeModelYearList(params).then((res: any) => {
        Object.assign(vehicleSelectList, res);
        return res;
      });
    };

    const onProgExport = () => {
      visibleVehicle.value = true;
      isProgPard.value = true;
      getSelectList().then((res: any) => {
        classSelect.value = res.typeClass;
      });
      value1201.value = valueBrand.value;
    };

    // 选择车辆信息的变量(1201 => brand; 1203 => Type class; 1204 => Model year; 1205 => Model name)
    const value1203 = ref([]);
    const value1204 = ref([]);
    const value1205 = ref([]);
    // const vehicleSelect = ref<string[]>([])

    const valueTypeClass = ref([]);
    const valueModel = ref([]);

    // 新增 nst group name
    const exportActiveKey = ref('1');
    const valueNstGroupName = ref<any[]>([]);
    const nstGroupNameList = ref<any[]>([]);
    const exportTabChange = () => {
      valueModel.value = [];
      valueNstGroupName.value = [];
    };

    // 给下拉文件列表赋值
    const valueFile = ref();
    const fileSelectList = ref<FileProps[]>([]);
    const assignFileList = (data: FileProps[]) => {
      fileSelectList.value = [];
      data.forEach((item) => {
        fileSelectList.value.push({
          excelName: item.excelName,
          sourceFilePath: item.sourceFilePath
        });
      });
    };
    const valueModelYear = ref([]);
    const modelYearSelect = ref<any[]>([]);
    // 下载vehicle 选择typeclass失焦
    const handleBlurTypeClass = () => {
      modelSelect.value = [];
      if (!isProgPard.value) {
        nstGroupNameList.value = [];
        valueTypeClass.value.forEach((item) => {
          modelSelect.value.push(...(vehicleSelectData.typeClassAndModel[item] as []));
          nstGroupNameList.value.push(...(vehicleSelectData.typeClassAndNstGroup[item] as []));
        });
        const params = {
          typeClass: valueTypeClass.value,
          model: valueModel.value.join(),
          brand: valueBrand.value,
          customerType: valueCustomer.value,
          offerType: offerType.value,
          offer: valueMark.value,
          bu: valueBu.value
        };
        getFileList({ params }).then((res) => {
          assignFileList(res);
        });
      } else {
        modelYearSelect.value = [];
        valueModelYear.value = [];
        getSelectList({ typeClass: valueTypeClass.value }).then(() => {
          const tempModelYearList: any[] = [];
          valueTypeClass.value.forEach((item) => {
            tempModelYearList.push(...(vehicleSelectList.typeClassMapperModelYear[item] as []));
          });
          modelYearSelect.value = Array.from(new Set(tempModelYearList));
        });
      }
      modelYearSelect.value = Array.from(new Set(modelYearSelect.value));
    };

    const handleBlurModelYear = () => {
      modelSelect.value = [];
      valueModel.value = [];
      valueNstGroupName.value = [];
      nstGroupNameList.value = [];
      getSelectList({
        typeClass: valueTypeClass.value,
        modelYear: valueModelYear.value
      }).then(() => {
        const tempModelList: any[] = [];
        const tempNstList: any[] = [];
        valueModelYear.value.forEach((item) => {
          if (item in vehicleSelectList.modeYearMapperModel) {
            tempModelList.push(...(vehicleSelectList.modeYearMapperModel[item] as []));
          }
          if (item in vehicleSelectList.modeYearMapperGroup) {
            tempNstList.push(...(vehicleSelectList.modeYearMapperGroup[item] as []));
          }
        });
        modelSelect.value = Array.from(new Set(tempModelList));
        nstGroupNameList.value = Array.from(new Set(tempNstList));
      });
    };
    // model 失去焦点
    const fetching = ref(false);
    const handleBlurModel = () => {
      if (!isProgPard.value) {
        const params = {
          typeClass: valueTypeClass.value,
          model: valueModel.value.join(),
          nstGroupName: valueNstGroupName.value.join(','),
          brand: valueBrand.value,
          customerType: valueCustomer.value,
          offerType: offerType.value,
          offer: valueMark.value,
          bu: valueBu.value
        };
        // fetching.value = true
        getFileList({ params }).then((res) => {
          assignFileList(res);
        });
      }
    };

    // 选择车辆的下拉失去焦点的时候
    const handleBlur = () => {
      fileSelectList.value = [];
    };

    // 下载文件
    const genExportParams = () => {
      const quarter = {
        Q1: ['01', '02', '03'],
        Q2: ['04', '05', '06'],
        Q3: ['07', '08', '09'],
        Q4: ['10', '11', '12']
      };
      const quarterTomonth: string[] = [];
      qm.value.forEach((item) => {
        if (quarter[item]) {
          quarterTomonth.push(...quarter[item]);
        } else {
          quarterTomonth.push(item);
        }
      });

      return {
        typeClass: valueTypeClass.value.join(),
        modelYear: valueModelYear.value.join(),
        model: valueModel.value.join(),
        nstGroupName: valueNstGroupName.value.join(','),
        brand: valueBrand.value,
        year: year.value ? year.value : moment().add('month', 1).format('YYYY'),
        month: quarterTomonth.join(),
        customerType: valueCustomer.value,
        offerType: offerType.value,
        offer: valueMark.value,
        bu: valueBu.value
      };
    };

    const onExport = () => {
      // 判断是vehicle 还是 program的下载
      if (!isProgPard.value) {
        const fileName = fileSelectList.value.map((item) => item.sourceFilePath);
        const params = {
          fileName: valueFile.value ? valueFile.value : fileName.join(',')
        };
        if (!params.fileName) {
          message.error('请选择有效的文件');
          return;
        }
        const config = {
          url: `pgapi/prog/setting/fa/download_and_insert_file`,
          params
        };
        blobDownload(config);
      } else {
        const config = {
          url: `/pgapi/program_approval/exportProgramReport`,
          method: 'post',
          params: genExportParams()
        };
        blobDownload(config);
        onExportCancel();
      }
    };
    const vehicleSelect = ref([]);
    // 关闭抽屉前清空数据
    const onExportCancel = () => {
      valueTypeClass.value = [];
      valueModel.value = [];
      valueFile.value = '';
      fileSelectList.value = [];
      modelSelect.value = [];
      valueModelYear.value = [];
      modelYearSelect.value = [];
      classSelect.value = [];
      valueNstGroupName.value = [];
      nstGroupNameList.value = [];
      if (isProgPard.value) {
        qm.value = [getDefaultQm()];
      }

      visibleVehicle.value = false;
    };

    // wait modal
    const visibleWait = ref(false);

    // Upload drawer 抽屉
    const visibleUpload = ref(false);

    // 当buydown 上传的时候有必选项验证
    const onCaculatorUpload = () => {
      if (offerType.value !== 'fa') {
        if (!valueBu.value || !valueBrand.value) {
          message.error('Bu 和 brand是必选项');
          return false;
        }
        if (valueCustomer.value || valueMark.value) {
          visibleUpload.value = true;
        } else {
          message.error('Customer Type和Special Mark至少选一个');
        }
      } else {
        visibleUpload.value = true;
      }
    };
    const headers = {
      Authorization: store.state.Authorization
    };

    const getCalculatorParams = (file: any) => {
      const formData = new FormData();
      formData.append('file', file);
      const params = {
        offerType: offerType.value,
        bu: valueBu.value,
        brand: valueBrand.value,
        customerType: valueCustomer.value,
        offer: valueMark.value
      };
      return { formData, params };
    };

    const handleCustomRequest = (e: any) => {
      const { formData, params } = getCalculatorParams(e.file);
      validateCalculator(formData, params).then((res: any) => {
        if (res.isConfirm) {
          Modal.confirm({
            title: 'Tips',
            content: 'Please confirm to update Calculator data.',
            okText: 'Confirm',
            onOk() {
              uploadCalculator(formData, params)
                .then((res: any) => {
                  e.onSuccess(res, e);
                })
                .catch((err: any) => {
                  e.onError(err, err.response.data);
                });
            },
            onCancel() {
              console.log('Cancel');
            }
          });
        } else {
          uploadCalculator(formData, params)
            .then((res: any) => {
              e.onSuccess(res, e);
            })
            .catch((err: any) => {
              e.onError(err, err.response.data);
            });
        }
      });
    };

    const uploadMsg = ref('');
    const detailMsg = ref('');

    const isUploadDone = ref(true);
    const isUploadType = ref(true);
    const uploadChange = (info: FileInfo) => {
      if (info.file.status === 'done') {
        visibleWait.value = true;
        if ((info.file.response as FileResponse).message?.includes('unsuccessful')) {
          if (!(info.file.response as FileResponse).data) {
            isUploadType.value = false;
          } else {
            isUploadType.value = true;
          }
          isUploadDone.value = false;
          detailMsg.value = (info.file.response as FileResponse).data;
          uploadMsg.value = (info.file.response as FileResponse).message;
        } else {
          isUploadDone.value = true;
          uploadMsg.value = (info.file.response as any).data.fileMessage;
          isUploadType.value = true;
          initCalculatorMaintain();
        }
      } else if (info.file.status === 'error') {
        visibleWait.value = true;
        isUploadDone.value = false;
        uploadMsg.value = 'Data validation unsuccessful, please check and re-upload calculator';
        detailMsg.value = (info.file.response as FileResponse).message;
      }
    };

    // 上传dbd
    const visibleDbd = ref(false);
    const isUploadDoneDbd = ref(true);
    const uploadChangeDbd = (info: FileInfo) => {
      if (info.file.status === 'done') {
        visibleDbd.value = true;
        isUploadDoneDbd.value = true;
        uploadMsg.value = 'Calculator upload successfully!';
        initCalculatorMaintain();
      } else if (info.file.status === 'error') {
        visibleDbd.value = true;
        isUploadDoneDbd.value = false;
        uploadMsg.value = 'Data validation unsuccessful, please check and re-upload calculator.';
      }
    };

    // 上传dbd
    const visibleFree = ref(false);
    const isUploadDoneFree = ref(true);
    const uploadChangeFree = (info: FileInfo) => {
      if (info.file.status === 'done') {
        visibleFree.value = true;
        isUploadDoneFree.value = true;
        uploadMsg.value = 'Calculator upload successfully!';
        initCalculatorMaintain();
      } else if (info.file.status === 'error') {
        visibleFree.value = true;
        isUploadDoneFree.value = false;
        uploadMsg.value = 'Data validation unsuccessful, please check and re-upload calculator.';
      }
    };

    // 上传文件
    const fileList = ref<any[]>([]);
    const fileListDbd = ref([]);
    const fileListFree = ref([]);

    // onclose
    const onClose = () => {
      visibleWait.value = false;
      if (!isUploadDone.value) fileList.value.pop();
    };

    const onCloseDbd = () => {
      visibleDbd.value = false;
      if (!isUploadDoneDbd.value) fileListDbd.value.pop();
    };

    const onCloseFree = () => {
      visibleFree.value = false;
      if (!isUploadDoneFree.value) fileListFree.value.pop();
    };

    // 查看错误信息
    const visibleDetail = ref(false);
    const onDetail = () => {
      visibleDetail.value = true;
    };

    const onCancelUpload = () => {
      fileList.value = [];
      fileListDbd.value = [];
      fileListFree.value = [];
      visibleUpload.value = false;
    };
    //新改需求默认不选择Type class  和  Model name  获取所有的file
    watch(
      () => visibleVehicle.value,
      (val) => {
        if (val && !isProgPard.value) {
          // handleFocusFile();
        }
      }
    );

    watch(
      () => {
        return [valueCustomer.value, valueMark.value];
      },
      ([newCustomer, newMark]) => {
        if (typeof newCustomer === 'undefined') {
          valueCustomer.value = '';
        }
        if (typeof newMark === 'undefined') {
          valueMark.value = '';
        }
      }
    );

    const supplementaryCalculatorVisible = ref(false);
    const handleSupplementaryCalculatorUpload = () => {
      supplementaryCalculatorVisible.value = true;
    };
    const closeSupplementaryCalculator = () => {
      supplementaryCalculatorVisible.value = false;
    };

    onMounted(() => {
      initData();
    });

    return {
      tableHeight,
      jumpTo,
      // 品牌下拉的数据

      RefselectPeriod,
      activeKey,
      cutTabs,
      handleSelectBrand,
      changePage,
      changSize,
      pageIndex,
      pageSize,
      totalElements,

      buSelect,
      brandsSelect,
      customerSelect,
      markSelect,
      buyBrandSelect,
      valueBu,
      valueMark,
      valueCustomer,
      valueModel,

      handleSelectBu,

      dataSource,
      expandIcon,
      valueBrand,
      columns,

      value1201,
      value1203,
      value1204,
      value1205,
      vehicleSelect,
      handleBlur,

      valueFile,
      fileSelectList,
      onExport,
      onExportCancel,

      visibleVehicle,
      visibleUpload,
      visibleWait,
      onProgExport,
      onVehicExport,
      isProgPard,

      onClose,
      onCloseDbd,
      onCloseFree,
      fileList,
      fileListDbd,
      fileListFree,
      headers,
      uploadChange,
      uploadChangeDbd,
      uploadChangeFree,
      visibleDbd,
      visibleFree,
      uploadMsg,
      isUploadDone,
      isUploadDoneDbd,
      isUploadDoneFree,
      visibleDetail,
      onDetail,
      detailMsg,
      isUploadType,
      onCancelUpload,
      fetching,
      baseURL,

      onSearch,
      offerType,
      classSelect,
      modelSelect,
      valueTypeClass,
      handleBlurTypeClass,
      handleBlurModel,
      onCaculatorUpload,
      // changTypeClass,
      handleBlurModelYear,
      valueModelYear,
      modelYearSelect,

      //year q/m
      year,
      qm,
      openYear,
      handlerOpenChange,
      handlerPanelChange,
      qmOptions,
      SHOW_PARENT,

      valueNstGroupName,
      nstGroupNameList,
      exportActiveKey,
      exportTabChange,

      handleSupplementaryCalculatorUpload,
      supplementaryCalculatorVisible,
      closeSupplementaryCalculator,

      handleCustomRequest
    };
  }
});
